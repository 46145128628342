import { Box,  Container, Grid, Typography } from "@mui/material"
import { BACKGROUND_COLOR_1,  LOGO_ICON_COLOR,   LOGO_TEXT_COLOR, NORMAL_TEXT_COLOR } from "../../app/config"
import {  NavigateButton } from "../../Component/MyButtons"
import { ScheduleNote, ScheduleTable } from "./ScheduleTable"

const CodeSchedule = (props) => {
    const {schedules} = props
    return <Box sx={{ width: "100%", bgcolor: BACKGROUND_COLOR_1, pt: {xs:8,md:12},pb:{xs:8,md:12} }}>
        <Container maxWidth="lg" id="mainblock">
            <Grid container>
                <Grid item xs={12} md={5}>
                    <Box sx={{ display: "flex", flexDirection: "column", flexWrap: "wrap", alignItems: "left", }}>
                        <Typography variant="h4" component="div" sx={{ color: LOGO_TEXT_COLOR, mb: "16px", fontWeight: "bold" }}>
                            Computer Programming Courses<br></br>for <font color={LOGO_ICON_COLOR}>Grades 4 ~ 12.</font>
                        </Typography>
                        <Typography variant="h6" component="div" sx={{ color: NORMAL_TEXT_COLOR }}>
                            <font color={LOGO_TEXT_COLOR} >Level 1:</font> Python basics and core logic
                        </Typography>
                        <Typography variant="h6" component="div" sx={{ color: NORMAL_TEXT_COLOR }}>
                            <font color={LOGO_TEXT_COLOR} >Level 2:</font> Programming logic training, Comprehensive project
                        </Typography>
                        <Typography variant="h6" component="div" sx={{ color: NORMAL_TEXT_COLOR }}>
                            <font color={LOGO_TEXT_COLOR} >Level 3:</font> Data objects, String, Function, Advanced project
                        </Typography>
                        <Typography variant="h6" component="div" sx={{ color: NORMAL_TEXT_COLOR }}>
                            <font color={LOGO_TEXT_COLOR} >Level 4:</font> I/O, Exceptions, Data structures and Algorithms
                        </Typography>
                        <Typography variant="h6" component="div" sx={{ color: NORMAL_TEXT_COLOR }}>
                            <font color={LOGO_TEXT_COLOR} >Competition Class:</font> Junior & Senior, CCC & USACO
                        </Typography>
                        <Typography variant="h6" component="div" sx={{ color: NORMAL_TEXT_COLOR }}>
                            <font color={LOGO_TEXT_COLOR} >Advanced Class:</font> AP Computer Science A (Grade 9+)
                        </Typography>
                        <Box sx={{ flexDirection: "row", alignItems: "flex-start", display: "flex", mt: 2 }}>
                            <NavigateButton variant="contained" url="/register#code" sx={{width: "160px", mr: 2 }} text="Register" />
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Box sx={{ display: "flex", flexDirection: "row", mt: {xs:4,md:0}, ml: {xs:0,md:8}, flexWrap: "wrap", alignItems: "center", }}>
                        {schedules?.code?.plans?.map((s,idx)=>{
                            return <ScheduleTable sx={{mt:idx>0?2:0}} showHead={true} key={idx} title={s.category} datas={s.plan} />
                        })} 
                    </Box>

                </Grid>
            </Grid>
        </Container>
    </Box>
}

export default CodeSchedule