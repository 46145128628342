import { Box } from "@mui/material"
import { useEffect } from "react"
import About from "./fragement/About"
import ContactUs from "./fragement/ContactUs"
import CourseDetail from "./fragement/CourseDetail"
import Feature from "./fragement/Feature"
import MainBlock from "./fragement/MainBlock"

const HomePage = ()=>{    
    return <Box>
        <MainBlock />           
        <CourseDetail />
        <Feature />     
        <About />
        <ContactUs />
    </Box>
}

export default HomePage