import apis from "."
import { LOGO_ICON_COLOR, LOGO_SLOGAN_COLOR, LOGO_TEXT_COLOR } from "../app/config"
import { apiResult, sessionSet } from "../Utils/Common"


const ScheduleDatas = {
    code: [
        {
            title: "2023 Summer Schedule",
            plan: [
                { id: 0, level: "Level 1", wdate: "Mon~Fri", time: "9:00~10:30", bdate: "7/3~7/28", lessons: 16 },
                { id: 1, level: "Level 2", wdate: "Mon~Fri", time: "10:45~12:15", bdate: "7/3~7/28", lessons: 16 }
            ]
        },
        {
            title: "2023 Sept. Schedule",
            plan: [
                { id: 0, level: "Level 1 (New)", wdate: "Wednesday", time: "16:30~18:00", bdate: "9/13~", lessons: 16 },
                { id: 1, level: "Level 1 (New)", wdate: "Wednesday", time: "18:30~20:00", bdate: "9/13~", lessons: 16 }
            ]
        }
    ],
    cmath: [
        {
            title: "2023 Sept. Schedule",
            plan: [
                { id: 0, level: "Pre-Level (New)", wdate: "Friday", time: "18:45~20:15", bdate: "9/8-", lessons: 10 },
                { id: 1, level: "Level 1 (New)", wdate: "Wednesday", time: "16:30~18:30", bdate: "9/8-", lessons: 10 },
                { id: 2, level: "Level 1", wdate: "Tuesday", time: "19:15~21:15", bdate: "9/5-", lessons: 10 },
                { id: 3, level: "Level 2", wdate: "Tuesday", time: "17:00~19:00", bdate: "9/5-", lessons: 10 }
            ]
        }
    ],
    imath: [
        {
            title: "2023 Summer Schedule",
            plan: [
                { id: 0, level: "Grade 5~8", wdate: "Tue/Fri/Sun", time: "17:00~19:00", bdate: "7/2~7/28", lessons: 12 },
                { id: 1, level: "Grade 5~8", wdate: "Sat/Sun", time: "14:30~16:30", bdate: "7/1~8/30", lessons: 12 }
            ]
        }, {
            title: '2023 Sept. Schedule',
            plan: [
                { id: 0, level: "Grade 5 (New)", wdate: "Tuesday", time: "18:00~19:30", bdate: "9/5-", lessons: 16 },
                { id: 1, level: "Grade 6 (New)", wdate: "Saturday", time: "14:30~16:00", bdate: "9/9-", lessons: 16 }
            ]
        }
    ]
}

const NewClassLoader = (func, error) => {
    // let newClass = sessionGet("NewClass")
    // if (newClass && newClass != null) {
    //     func(newClass)
    //     return
    // }
    const itemStatus = (item) => {
        let status = <font color={LOGO_SLOGAN_COLOR}>Opening</font>
        if (item.paidcount >= item.maxcount) {
            status = <font color={"red"}>Full({item.regcount})</font>
        } else if (item.paidcount >= item.opencount) {
            status = <font color={LOGO_TEXT_COLOR}>Ready({item.regcount})</font>
        } else if (item.paidcount >= item.readycount) {
            status = <font color={LOGO_ICON_COLOR}>Almost Ready ({item.regcount})</font>
        } else if (item.regcount >= item.opencount) {
            status = <font color={LOGO_SLOGAN_COLOR}>Opening({item.regcount})</font>
        }else {
            status = <font color={LOGO_SLOGAN_COLOR}>Opening({item.regcount})</font>
        }
        return status
    }
    apis.loadNewClass().then(ret => {
        apiResult(ret, datas => {
            let clses = {}
            for (let item of datas) {
                let pp = { id: item.id,course:item.course, level: item.level, wdate: item.wdate,paidcount:item.paidcount, times: item.times, bdate: item.bdate + "-" + (item.edate && item.edate != null ? item.edate : ""), lessons: item.lessons, price: item.price, regcount: item.regcount,maxcount:item.maxcount,opencount:item.maxcount-item.paidcount,status: itemStatus(item) }
                if (clses[item.name]) {
                    let exists = false
                    for(let plan of clses[item.name].plans) {
                        if(plan.category == item.category) {
                            plan.plan.push(pp)
                            exists = true
                            break
                        }
                    }
                    if(!exists) {
                        clses[item.name].plans.push({
                            category: item.category, plan: [pp]
                        })
                    }
                } else {
                    clses[item.name] = {
                        courseName: item.course,
                        plans: [
                            {
                                category: item.category, plan: [pp]
                            }
                        ]
                    }
                }
            }
            //sessionSet("NewClass",clses)
            console.log(clses)
            func(clses)
        }, error)
    })
}

export { NewClassLoader }