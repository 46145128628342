import { Business, Mail, Phone } from "@mui/icons-material"
import { Box, Container, Grid, Typography } from "@mui/material"
import { BACKGROUND_COLOR_1, LOGO_TEXT_COLOR,NORMAL_TEXT_COLOR } from "../../app/config"

const ContactUs = (props) => {
    return (
        <Box sx={{ width: "100%", bgcolor: BACKGROUND_COLOR_1,pb:8,pt:8}}>
            <Container maxWidth="lg" id="contact">
                <Box sx={{ flexDirection: "column", display: 'flex', alignItems: "center" }}>
                    <a href="#contact" />
                    {/* <Typography variant="body1" align="center" sx={{ color: NORMAL_TEXT_COLOR }}>CONTACT US</Typography>         */}
                    <Typography variant="h3" sx={{ color: LOGO_TEXT_COLOR,fontWeight: "bold", mt: 2  }}>Contact us</Typography>            
                    <Grid container sx={{ mt: 4 }} spacing={2}>
                        <Grid item xs={12} md={6}>
                            <Box sx={{ flexDirection: "column", display: 'flex', alignItems: "Left" }}>
                                <Typography variant="h6" sx={{ color: LOGO_TEXT_COLOR,mb:2 }}>Contact us</Typography>
                                <Typography variant="h6" sx={{ color: NORMAL_TEXT_COLOR,}}><Mail fontSize="small" /> <a style={{color: NORMAL_TEXT_COLOR}} href="mailto:info@linkfuture.ca">info@linkfuture.ca</a></Typography>
                                <Typography variant="h6" sx={{ color: NORMAL_TEXT_COLOR, mt: 1 }}><Phone fontSize="small" /> <a style={{color: NORMAL_TEXT_COLOR}} href="tel:6472898503">(647) 289-8503 (EN)</a></Typography>
                                <Typography variant="h6" sx={{ color: NORMAL_TEXT_COLOR, mt: 1 }}><Phone fontSize="small" /> <a style={{color: NORMAL_TEXT_COLOR}} href="tel:4167259206">(416) 725-9206 (中)</a></Typography>
                                <Typography variant="h6" sx={{ color: NORMAL_TEXT_COLOR, mt: 1 }}><Business fontSize="small" />  <a style={{color: NORMAL_TEXT_COLOR}} href="https://maps.app.goo.gl/cGmQxgTkdvefh6FGA" target="_blank">47 The Links Road, North York, ON. M2P 1T7</a></Typography>
                                <Typography variant="h6" sx={{ color: LOGO_TEXT_COLOR,mt:4}}>WeChat: </Typography>
                                <Box>
                                    <img src="/leowechat.jpg" width="160px" />
                                </Box>
                            </Box>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <Typography variant="h6" sx={{ color: LOGO_TEXT_COLOR,mb:2 }}>Based in the community, serving the community.</Typography>
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d846.5472812715465!2d-79.40166426712808!3d43.75121511185357!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b32a1919ce767%3A0x45aaeb73e307d432!2s47%20The%20Links%20Rd%2C%20North%20York%2C%20ON%20M2P%201T7!5e1!3m2!1sen!2sca!4v1728957870480!5m2!1sen!2sca" width="100%" height="400px" style={{ border: 0 }} allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>                            
                            
                        </Grid>

                    </Grid>
                </Box>
            </Container>
        </Box>
    )
}
export default ContactUs