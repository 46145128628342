import { Box, Typography } from "@mui/material"
import { BLANK_BLOCK_COLOR, LOGO_ICON_COLOR, LOGO_SLOGAN_COLOR, NORMAL_TEXT_COLOR } from "../../app/config"
import MyTable from "../../Component/MyTable"

const defaultTableHeader = [
    { name:'level',showName:"Level"},
    {name: 'bdate', showName: 'Duration',func:(v,idx,row)=>{
        return v +" - "+(row.edate?row.edate:"")
    }},
    { name: 'wdate', showName: 'Week Date',func:(v,idx,row)=>{
        return v +" "+row.times
    }},
    { name: 'lessons', showName: 'Lessons',func:(v,idx,row)=>{
        return v + "($"+row.price+")"
    }},
    { name: 'opencount', showName: 'Available',func:(v,idx,row)=>{
        return v
    }},
]
const ScheduleTable = (props) => {
    const { title, datas, tableHeader,bgcolor,onSelected,showHead,defaultSelected } = props
    return <Box sx={{...props.sx,width:"100%"}}>
        {title && <Typography variant="body1" component="div" sx={{ color: NORMAL_TEXT_COLOR, mb: 1, pl: 1,fontSize:props.sx?.fontSize }}>
            {title}
        </Typography>}
        <MyTable
            sx={{fontSize:props.sx?.fontSize}}
            rows={datas}
            shadow={0}
            checkbox={onSelected?true:false}
            showHead = {showHead}
            defaultSelected = {defaultSelected}
            onSelected = {onSelected}
            headerColor={bgcolor?bgcolor:BLANK_BLOCK_COLOR}
            totalRow={datas.length}
            headers={tableHeader ? tableHeader : defaultTableHeader}
            rowsPerPage={datas.length}
            showPageination={false}
        />        
    </Box>
}
const ScheduleNote = (props) => {
    return <Typography variant="body2" component="div" sx={{ mt: 1.5, color: NORMAL_TEXT_COLOR, pl: 1 }}>
    {props.note}
</Typography>
}

export {ScheduleTable,ScheduleNote }