import { Alert, Box } from "@mui/material"
import { useEffect, useRef, useState } from "react"
import { NewClassLoader } from "../api/loader"
import CodeSchedule from "./fragement/CodeSchedule"
import DebateSchedule from "./fragement/DebateSchedule"
import IntensiveMath from "./fragement/IntensiveMath"
import MathSchedule from "./fragement/MathSchedule"
// import OtherSchedule from "./fragement/OtherSchedule"
import PsSchedule from "./fragement/PsSchedule"
import RWSchedule from "./fragement/RWSchedule"
import OtherSchedule from "./fragement/OtherSchedule"
import apis from "../api"
import { apiResult } from "../Utils/Common"
import CourseSchedule from "./fragement/CourseSchedule"


const Courses = () => {
    const [error,setError] = useState()
    const [courses,setCourses] = useState()
    const loading = useRef(false)
    useEffect(()=>{
       if(!loading.current) {
            loading.current = true
            apis.loadCourses(0,1).then(ret=>{
                apiResult(ret,data=>{
                    setCourses(data)
                },setError)
            })
       }
    },[])
    return <Box>
        {error && <Alert severity="error" onClose={()=>setError()}>{error}</Alert>}     
        {courses?.map((course,idx)=>{
            return <CourseSchedule course={course} key={idx} index={idx} />
        })}
        {/* <CodeSchedule schedules={schedules}/>
        <IntensiveMath  schedules={schedules}/>
        <MathSchedule  schedules={schedules}/>
        <RWSchedule schedules={schedules} />
        <PsSchedule schedules={schedules} />
        <DebateSchedule schedules={schedules} />
        <OtherSchedule schedules={schedules} />
        <OtherSchedule  schedules={schedules}/> */}
    </Box>
}

export default Courses