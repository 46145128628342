import { Box,  Container, Grid, Typography } from "@mui/material"
import { BACKGROUND_COLOR_1,  LOGO_ICON_COLOR,   LOGO_TEXT_COLOR, NORMAL_TEXT_COLOR } from "../../app/config"
import { NavigateButton } from "../../Component/MyButtons"
import { ScheduleNote, ScheduleTable } from "./ScheduleTable"

const RWSchedule = (props) => {
    const {schedules} = props
    return <Box sx={{ width: "100%", bgcolor: '#fff',pt: {xs:8,md:12},pb:{xs:8,md:12} }}>
        <Container maxWidth="lg" id="mainblock">
            {/* <img src="/math.jpg" width="200px" />                     */}            
            <Grid container>
                <Grid item xs={12} md={5}>
                    <Box sx={{ display: "flex", flexDirection: "row", flexWrap: "wrap", alignItems: "center", }}>                            
                        <Typography variant="h4" component="div" sx={{ color: LOGO_TEXT_COLOR,fontWeight: "bold" }}>
                            Reading & Writing <br></br>for <font color={LOGO_ICON_COLOR}>Grades 4+</font>
                        </Typography>
                        <Typography variant="h6" component="div" sx={{ color: NORMAL_TEXT_COLOR }}>
                            Our reading class led by K-12 reading experts is full of fun and discusses a variety of books and literature in depth. It encourages students to share and exchange ideas and learn to analyze the reading through short answer questions, activities, and essays. <br></br>
                            By guiding students through developing thesis and paragraphs, using outlines and the correct grammar, our experienced tutor helps them achieve a substantial improvement in academic writing. We also introduce students with elements of creative writing and literary/poetic devices to help enhance both the analytical and practical skills in literature. The research essay class is designated to offer guidance to intermediate students in writing research essays in English and Social Studies.
                        </Typography>  
                        <Box sx={{ flexDirection: "row", alignItems: "flex-start", display: "flex", mt: 2 }}>
                            <NavigateButton variant="contained" url="/register#rw" sx={{width: "160px", mr: 2 }} text="Register" />
                        </Box>                      
                    </Box>
                </Grid>
                <Grid item xs={12} md={7}>
                    <Box sx={{ display: "flex", flexDirection: "row", ml:{xs:0,md:8},mt:{xs:4,md:0}, flexWrap: "wrap", alignItems: "center", }}>  
                        {schedules?.rw?.plans?.map((s,idx)=>{
                            return <ScheduleTable sx={{mt:idx>0?2:0}} showHead bgcolor={BACKGROUND_COLOR_1} key={idx} title={s.category} datas={s.plan} />
                        })}
                        <ScheduleNote note="Class schedule will be updated continuously. (New) indicates a new class, and existing classes will continue according to the current time" />
                    </Box>
                </Grid>
            </Grid>
        </Container>
    </Box>
}

export default RWSchedule