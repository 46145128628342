import { Box, Container, Grid, Typography } from "@mui/material"
import { BACKGROUND_COLOR_1, LOGO_ICON_COLOR,LOGO_TEXT_COLOR } from "../../app/config"
import { ContainedButton, NavigateButton } from "../../Component/MyButtons"

const MainBlock = (props) => {
    return (
        <Box sx={{ bgcolor: BACKGROUND_COLOR_1, width: "100%", pt: {xs:8,md:16}, pb: {xs:8,md:16}}}>
            <Container maxWidth="lg" id="mainblock">
                <Grid container>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ display: "flex", flexDirection: "row",pt:6,pb:6, flexWrap: "wrap", alignItems: "center", }}>
                            <Typography variant="h2" component="div" sx={{ color:LOGO_TEXT_COLOR,mb: "16px",fontWeight:"bold" }}>
                                Link kids' future<br></br>with <font color={LOGO_ICON_COLOR}>success.</font>
                            </Typography>
                            <Typography variant="h6"  component="div" sx={{ mb: "24px" }}>
                            LinkFuture cultivates logical thinking, builds engineering culture, and encourages leadership in future technology trends.
                            </Typography>
                            <Box sx={{flexDirection:"row",alignItems:"flex-start",display:"flex"}}>
                                <NavigateButton variant="contained" sx={{width:"160px",mr:2}} url="/schedule" text="Get Started"></NavigateButton>
                                {/* <Button variant="outlined" sx={{borderColor:LOGO_ICON_COLOR,color:LOGO_ICON_COLOR,width:"160px"}} >More Detail</Button> */}
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{display:{xs:'none',md:'flex'}}}>
                        <Box sx={{flexDirection:"column",display:"flex",ml:"auto"}}>                                                        
                            <img style={{maxHeight:"400px"}} src="/rocket.png" />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    )
}
export default MainBlock